import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

// Home components
import Image from "../components/image"
import IntroBlock from "../components/intro-block"
import WhatIsMi from "../components/what-is-em"
import WhyMi from "../components/why-mi"
import HomeCourses from "../components/home-courses"
import HomeNews from "../components/home-news"
import Team from "../components/team"

import Contact from "../components/contact"

import SEO from "../components/seo"

import news from "../content/news"

const IndexPage = () => (
  <Layout>
    <IntroBlock />
    <WhatIsMi />
    <WhyMi />
		<Team />
		<HomeCourses />
		<HomeNews news={news} />
    <Contact />
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
  </Layout>
)

export default IndexPage
