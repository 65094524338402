import React from "react"

const WhyMi = ({}) => (
   <div className="bg-gray">
      <article className="why-lms-block container">
				<div className="row">
					<div className="col-xs-12 col-md-12 col">
            <h2 className="sep-heading">ENTREVISTA MOTIVACIONAL ARGENTINA</h2>
						<div className="panel-group why-panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="headingOne">
									<h4 className="panel-title fw-normal">
										<a className="accOpener element-block collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">¿Qué es Entrevista Motivacional Argentina (EMA)?</a>
									</h4>
								</div>
								<div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne" aria-expanded="true">
									<div className="panel-body">
                    <p>En EMA ofrecemos capacitación y consultoría personalizadas a profesionales,
                    organizaciones pequeñas, medianas y grandes de habla Hispana. Nos especializamos
                    en guiar la incorporación de la Entrevista Motivacional a la práctica cotidiana.</p>
									</div>
								</div>
							</div>
							<div className="panel panel-default">
								<div className="panel-heading" role="tab" id="headingTwo">
									<h4 className="panel-title fw-normal">
										<a className="accOpener element-block" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">¿En qué me puede ayudar la incorporación de las herramientas de la Entrevista Motivacional en la práctica cotidiana?</a>
									</h4>
								</div>
								<div id="collapseTwo" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo" aria-expanded="true">
									<div className="panel-body">
                    <p>Utilizar las herramientas y enfoque de la entrevista Motivacional
                    ayuda a mejorar la eficacia y adherencia terapéutica de los pacientes,
                    favorecer la consolidación de vínculos terapéuticos y aumentar la satisfacción
                    de los pacientes en la atención recibida.</p>
                    <p> Al mismo tiempo favorece la satisfacción y compromiso de los profesionales
                    en su tarea protegiendolos del agotamiento profesional.</p>
                    <p>En la gestión administrativa y atención al público mejora la experiencia del
                    cliente y la percepción de control de la entrevista por parte de
                    personal favoreciendo la resolución manejo de conflictos.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</article>
   </div>
)

export default WhyMi
