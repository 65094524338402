import React from "react"

const IntroBlock = ({ siteTitle }) => (
      <section className="intro-block">
				<div className="slider fade-slider">
					<div>
						<article className="intro-block-slide overlay bg-cover" style={{ backgroundImage: 'url(/images/test.jpg)' }}>
							<div className="align-wrap container">
								<div className="align">
									<div>
										<h1 className="intro-block-heading">Entrevista Motivacional Argentina</h1>
									</div>
									<div>
										<p>Nos especializamos en capacitación y coaching para incorporar la
                    comunicación centrada en la persona a tu práctica profesional o tu empresa.</p>
									</div>
									<div>
										<div className="btns-wrap">
											<a href="/#home-courses" className="btn btn-warning btn-theme text-uppercase">Nuestros Cursos</a>
											<a href="#contact" className="btn btn-white text-uppercase">Contacto</a>
										</div>
									</div>
								</div>
							</div>
						</article>
					</div>
					<div>
						<article className="intro-block-slide overlay bg-cover" style={{ backgroundImage: 'url(/images/test.jpg)' }}>
							<div className="align-wrap container">
								<div className="align">
									<div>
										<h1 className="intro-block-heading">Entrevista Motivacional Argentina</h1>
									</div>
									<div>
										<p>Nos especializamos en capacitación y coaching para incorporar la
                    comunicación centrada en la persona a tu práctica profesional o tu empresa.</p>
									</div>
									<div>
										<div className="btns-wrap">
											<a href="/#home-courses" className="btn btn-warning btn-theme text-uppercase">Nuestros Cursos</a>
											<a href="/#contact" className="btn btn-white text-uppercase">Contacto</a>
										</div>
									</div>
								</div>
							</div>
						</article>
					</div>

				</div>
      </section>
)

export default IntroBlock
