const allNews = [
{
    title: 'Taller presencial Inicial de Entrenamiento en Entrevista Motivacional',
    slug: '/news/curso-basico-en-entrevista-motivacional-agosto-2019',
    day: '27',
    month: 'Septiembre',
    weekDay: 'Viernes',
    summary: 'Este taller intensivo dos días le proporcionará a los participantes una introducción completa a la teoría, espíritu, herramientas comunicacionales y procesos de la Entrevista Motivacional y se recomienda para todo el personal de Atención Primaria.',
    linkText: 'Más información',
    link: '/courses/taller-basico/index.html',
    //image: '/images/curso30ago.jpg'
    image: '/images/curso27sep.jpg'
},
{
    title: 'Taller presencial inicial de Entrenamiento en Entrevista Motivacional',
    slug: '/news/curso-virtual-en-entrevista-motivacional-abril-2017',
    day: '14',
    month: 'Mayo',
    weekDay: 'Viernes',
    summary: 'Este taller intensivo dos días le proporcionará a los participantes una introducción completa a la teoría, espíritu, herramientas comunicacionales y procesos de la Entrevista Motivacional y se recomienda para todo el personal de Atención Primaria.',
    linkText: 'Más información',
    link: '/courses/taller-basico/index.html',
    image: '/images/proximo-presencial.png'
},
{
    title: 'Curso Virtual en Entrevista Motivacional',
    slug: '/news/curso-virtual-en-entrevista-motivacional-abril-2017',
    day: '17',
    month: 'Abril',
    weekDay: 'Miércoles',
    summary: 'El curso está especialmente destinado a todos los profesionales de la salud interesados en la temática del curso y en el trabajo con personas que necesiten ayuda para un cambio de hábitos (médicos, licenciados en nutrición, enfermería, psicología, trabajadores sociales, etc.). Dirección: Dra Viviana Visús (miembro MINT), Lic. Leila Gaipe, Dr Gastón Perman. Para más información seguir el link o enviar un correo a campus@hospitalitaliano.org.ar',
    linkText: 'Más información',
    link: 'https://www1.hospitalitaliano.org.ar/#!/edu/home/campus/producto/1523',
    image: '/images/em-hospital-italiano.png'
  },
  {
    title: 'Educación superior en adicciones. Córdoba',
    slug: '/news/educacion-superior-en-adicciones-abril-2019-cordoba',
    day: '05',
    month: 'Abril',
    weekDay: 'Viernes',
    summary: 'Introducción a la Entrevista Motivacional. Motivaciones personales y consumo de Sustancias. Encuentro de 8 hs de duración en el marco del curso de posgrado, diplomatura y curso de divulgación general organizados por la Universidad Provincial de Córdoba y la Secretaría de Prevención y Asistencia de las Adicciones.',
    linkText: 'Más información',
    link: 'http://www.upc.edu.ar/2019/02/26/nuevas-propuestas-de-educacion-superior-en-adicciones-2/',
    image: '/images/flyer.jpg'
  },
];

export default allNews
