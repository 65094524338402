
import React from "react"

const Course = ({course}) => (
  <article className="popular-post">
    <div className="aligncenter">
        <a href={course.slug + "/index.html"}><img src={"/images" + course.slug + ".jpg"}  alt="image description"></img></a>
    </div>
    <div>
      <strong className={"text-white font-lato text-uppercase price-tag" + " " + course.style_name}>{course.type}</strong>
    </div>
    <h3 className="post-heading"><a href={course.slug + "/index.html"}>{course.title}</a></h3>
  </article>
)

export default Course
