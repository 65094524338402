import React from "react"
import News from "../components/news"

function HomeNews(props) { 
  var rows = [];
  for(var i = 0; i < props.news.length; i++) {
    rows.push(<li><News newsItem={props.news[i]} /></li>);
  }

  return (
    <div id="home-news" className="bg-gray">
			<section className="upcoming-events-block container">
				<header className="block-header">
					<div className="pull-left">
						<h2 className="block-header-heading">AGENDA</h2>
						<p>A continuación listamos las próximas actividades que organizamos o en los que participaremos.</p>
					</div>
          {/* <a href="event-list.html" className="btn btn-default text-uppercase pull-right">Ver Más</a> */}
				</header>
				<ul className="list-unstyled upcoming-events-list">{rows}</ul>
			</section>
    </div>
  );
}

export default HomeNews
