import React from "react"

const News = ({newsItem}) => (
  <div>
    <div className="alignright">
      <img src={newsItem.image} alt="image description"></img>
    </div>
    <div className="alignleft">
      <time datetime="2011-01-12" className="time text-uppercase">
        <strong className="date fw-normal">{newsItem.day}</strong>
        <strong className="month fw-light font-lato">{newsItem.month}</strong>
        <strong className="day fw-light font-lato">{newsItem.weekDay}</strong>
      </time>
    </div>
    <div className="description-wrap">
      <h3 className="list-heading"><a href={newsItem.link}>{newsItem.title}</a></h3>
      <p>{newsItem.summary}</p>
      <a href={newsItem.link} className="btn btn-default text-uppercase" target="_blank">{newsItem.linkText}</a>
    </div>
  </div>
)

export default News
