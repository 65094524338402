
import React from "react"

const WhatIsMi = ({}) => (
      <article id="home-about" className="container text-info-block">
				<div className="row">
					<div className="col-xs-12 col-sm-6">
						<h1>&iquest;QUÉ ES ENTREVISTA MOTIVACIONAL?</h1>
            <p>La <strong>Entrevista Motivacional</strong> es un enfoque comunicacional centrado en la persona especialmente
                diseñado para guiar a las personas en cualquier decisión o cambio de comportamiento que deseen
                emprender.</p>
            <p>Se centra en el logro de un vínculo terapéutico empático para  desde allí colaborar en la
                resolución de la ambivalencia para el cambio, permitiéndole a cada persona trabajar desde
                su propia motivación, fortalezas y recursos internos.</p>
            <p>Este enfoque terapéutico ha sido desarrollado en parte por los psicólogos clínicos <i>William
                    R. Miller y Stephen Rollnick</i>. Luego de tres décadas del inicio de su aplicación, la
                Entrevista Motivacional ha llegado a ser un modelo desarrollado, maduro para la clínica y
                la formación y con suficiente investigación que avala su utilidad.</p>
					</div>
					<div className="col-xs-12 col-sm-6">
            <img src="/images/what-is-em.jpg" className="element-block image" alt="image description"></img>
					</div>
				</div>
			</article>
)

export default WhatIsMi
