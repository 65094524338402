
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Course from "../components/course"

function HomeCourses(props) {

  var rows = [];
  const courses = props.allMarkdownRemark.courses;
  for(var i = 0; i < courses.length; i++) {
    rows.push(<div class="col-xs-12 col-sm-6 col-lg-3"><Course course={courses[i].course.frontmatter} /></div>);
  }

  return (
			<section id="home-courses" className="popular-posts-block container">
				<header className="popular-posts-head">
					<h2 className="popular-head-heading">SERVICIOS Y CAPACITACIÓN</h2>
				</header>
				<div className="row">
        {rows}
       </div>
     </section>
  );
}

export default () => (
    <StaticQuery
        query={
          graphql`
            query {
              allMarkdownRemark(sort: { fields: [frontmatter___weight], order: ASC }) {
                courses:edges {
                  course:node {
                    frontmatter {
                      title
                      type
                      author
                      course_type
                      slug
                      style_name
                    }
                  }
                }
              }
            }`
          }
        render = {HomeCourses}
    />
);
