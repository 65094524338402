const teamMembers = [
  {
    name: 'Dra. Viviana Visús',
    position: 'Directora y Docente',
    bio: 'Soy Médica de Familia y especialista en docencia universitaria. Conferencista y entrenadora en Entrevista Motivacional con más de 10 años de experiencia. Miembro de la Red Internacional de Entrenadores en Entrevista Motivacional (MINT)',
    image: '/images/team/viviana-visus.jpg',
    linkedin: 'https://www.linkedin.com/in/viviana-visus/'
  },
  {
    name: 'Lic. Leila Garipe',
    position: 'Docente',
    bio: 'Soy Licenciada en Nutrición y Magister en Investigación Clínica. Especialista en Docencia Universitaria y Entrenadora en Entrevista Motivacional. Me encuentro orientada al diseño e implementación de Estrategias Comunitarias de Atención Sanitaria.',
    image: '/images/team/leila-garipe.jpg',
    linkedin: 'https://www.linkedin.com/in/leila-garipe-b7b7b823'
  },
  {
    name: 'Lic. Gimena Giardini',
    position: 'Docente',
    bio: 'Soy Licenciada en nutrición e Instructora en salud. Entrenadora en Entrevista Motivacional. Me desempeño como orientadora sociosanitaria del adulto mayor frágil en el ámbito domiciliario.',
    image: '/images/team/gimena-giardini.jpg',
    linkedin: ''
  },
  {
    name: 'Lic. Mar Lucas Gomez',
    position: 'Colaboradora',
    bio: 'Soy Licenciada en psicología desde el enfoque Gestalt y especialista en Psicogenealogía. Actualmente me encuentro trabajando en promoción de perspectiva de género. Acompaño a personas a llevar adelante tratamientos crónicos desde el modelo de la Entrevista Motivacional.',
    image: '/images/team/mar-lucas.jpg',
    linkedin: 'https://www.linkedin.com/in/mar-lucas-g%C3%B3mez-00806b36/'
  },
  {
    name: 'Lic. Lucila Falcone',
    position: 'Colaborador',
    bio: 'Soy Licenciada en Psicología y Magister en eHealth. Trabajo en el campo de la atención integral a personas con enfermedades crónicas avanzadas y necesidades de atención paliativa. Actualmente orientada a las metodologías que permiten conocer la Experiencia del Paciente y a los modelos de planificación centrada en la persona.  ',
    image: '/images/team/lucila-falcone.jpg',
    linkedin: 'https://www.linkedin.com/in/lucilafalcone/'
  },
];

export default teamMembers
