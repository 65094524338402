import React from "react"

import teamMembers from "../content/team"

function Member(props) {
  return (
    <article className="pro-column over text-center">
      <div className="aligncenter">
        <a href="#"><img src={props.image} alt={props.name}></img></a>
        <div className="caption">
          { props.linkedin != '' ?
          <ul className="socail-networks list-unstyled">
            <li><a href={props.linkedin} className="linkedin" target="_blank"><span className="fab fa-linkedin"></span></a></li>
          </ul>
            : ""
          }
        </div>
      </div>
      <h3 className="fw-normal text-capitalize"><a href="#">{props.name}</a></h3>
      <h4 className="fw-normal text-capitalize">{props.position}</h4>
      <p>{props.bio}</p>
    </article>
  );
}

function Team(props) {

  const rows = [];
  var cols = [];
  for(var i = 0; i < teamMembers.length; i++) {
    cols.push(<div className="col-xs-12 col-sm-6 col-md-4">{Member(teamMembers[i])}</div>);
    if (i % 3 == 2 && i > 0) {
      rows.push(<div className="row">{cols}</div>);
      cols = [];
    }
  }

  // Si quedaron columnas agragamos una fila con lo que quedó
  if (cols.length > 0) {
    rows.push(<div className="row">{cols}</div>);
  }

  return (
      <section id="home-team" className="container professionals-block">
        <header className="popular-posts-head"><h2 className="popular-head-heading">DIRECCIÓN Y EQUIPO DOCENTE</h2></header>
        {rows}
      </section>
  );
}

export default Team
